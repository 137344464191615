var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['table-box', _setup.props.border ? 'table-box-border' : '']},[_c('div',{ref:"containerRef",staticClass:"material-virtual-container",style:({
      width: _setup.containerFinalWidth + 'px',
      borderColor: _setup.props.borderColor,
    }),on:{"mousemove":function($event){return _setup.containerMove($event)},"mouseup":function($event){return _setup.containerUp($event)}}},[_c('div',{class:[
        'table-head flex flex-cross--center flex-main--left h100',
        _setup.props.border ? 'table-head-line' : '',
      ],style:({ borderColor: _setup.props.borderColor, textAlign: _setup.props.align })},_vm._l((_setup.tableHeadProp),function(item,idx){return _c('div',{key:item.prop,class:[
          'head-item',
          item.prop,
          idx === _setup.tableHeadProp.length - 1 ? 'fillBox border-none' : '',
          _setup.props.showHeadBorder ? 'table-head-right-border' : '',
          _vm.noEllipsis.includes(item.prop) ? '' : 'ellipsis',
          _setup.props.filterKeys?.includes(item.prop) ? 'is-filter' : '',
        ],style:({
          width: item.width,
          minWidth: item.minWidth,
          borderColor: _setup.props.borderColor,
        })},[_vm._t(item.headSlotName,function(){return [_c('span',{attrs:{"title":_setup.translateLang(item.label)}},[_vm._v(_vm._s(_setup.translateLang(item.label)))])]}),(
            _setup.props.hasFilter &&
            !['select', 'type', 'plankID', 'partName', 'hsInfo'].includes(
              item.prop
            )
          )?_c('i',{class:[
            'iconfont',
            'filter-icon',
            _setup.props.filterKeys?.includes(item.prop)
              ? 'icon-shaixuan'
              : 'icon-shaixuan1',
          ],staticStyle:{"color":"#18a8c7"},attrs:{"id":`${_setup.props.preffixID}_material_table_${item.prop}_filter_button`},on:{"click":function($event){return _setup.handlePlankFilter(item)}}}):_vm._e(),(idx !== _setup.tableHeadProp.length - 1)?_c('div',{class:['resizable', _setup.isShowResizableLine ? 'resize-default' : ''],on:{"mousedown":function($event){return _setup.mouseResizableDown($event, item)}}}):_vm._e()],2)}),0),(!_vm.isempty && !_setup.props.tableData.length)?_c('a-empty',{staticClass:"pt20 pb20",attrs:{"description":_vm.$t('materialPage.empty'),"image":require('@/assets/image/noMatchSurplus.png')}}):_vm._e(),_c(_setup.virtualList,{directives:[{name:"show",rawName:"v-show",value:(_setup.props.tableData.length),expression:"props.tableData.length"}],ref:"listRef",staticClass:"table",style:({ width: _setup.containerFinalWidth + 'px' }),attrs:{"data":_setup.props.tableData,"height":_setup.tableHeight,"loading":false,"className":"table"},on:{"scroll":_setup.handleScrollTable},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return _vm._l((items),function(item,idx){return _c('div',{key:item.partUniqueId,class:['group', _setup.props.border ? '' : 'group-border'],style:({
            height: _setup.props.singleItemHeight + 'px',
            borderColor: _setup.props.borderColor,
          }),attrs:{"id":'_' + item.partUniqueId}},[_c(item.isVirtualHead ? _setup.virtualHead : _setup.singleVirtualList,_vm._b({tag:"component",attrs:{"preffixID":_vm.$props.preffixID},on:{"itemClick":_setup.itemClick,"inputBlur":_setup.inputBlur,"inputChange":_setup.inputChange,"changeSelect":_setup.changeSelect,"changeSelectAll":_setup.changeSelectAll,"toggleFold":_setup.handleToggleFold},scopedSlots:_vm._u([_vm._l((_setup.tableHeadProp),function(item){return {key:item.prop,fn:function(scope){return [_vm._t(item.prop,null,{"index":idx},scope)]}}}),(item.isVirtualHead)?{key:"rowHead",fn:function(scope){return [_vm._t("rowHead",null,null,scope)]}}:null,{key:"after",fn:function(scope){return [_vm._t("after",null,{"index":idx},scope)]}}],null,true)},'component',_setup.setProps(item, idx),false))],1)})}}])}),_c('div',{ref:"resizableLine",class:['resizable-line', _setup.isShowResizableLine ? 'show-line' : 'none']}),(_setup.props.isShowBackTop)?_c('el-backtop',{style:({ backGroundColor: '#ccc' }),attrs:{"target":".vxe-list--virtual-wrapper","bottom":100}},[_c('i',{staticClass:"back-top el-icon-download"})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }